.modal.dataModal {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
}
.modal.dataModal .contentWrap {
  max-width: 800px;
  margin: 20px;
}
.modal.dataModal .contentWrap h2 {
  text-align: center;
  margin: 0 0 40px;
}
.modal.dataModal .contentWrap .dataPointsForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.modal.dataModal .contentWrap .dataPointsForm .field {
  margin: 0 0 10px;
  width: 100%;
}
.modal.dataModal .contentWrap .listResults {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
}
.modal.dataModal .contentWrap .titles article {
  flex: 1;
}
.modal.dataModal .contentWrap .titles article:nth-child(1), .modal.dataModal .contentWrap .titles article:nth-child(3) {
  flex: 1;
}
.modal.dataModal .contentWrap .titles article:last-child {
  flex: 0.1;
  width: 50px;
  display: block;
}
.modal.dataModal .contentWrap .listingItem {
  padding: 5px 10px;
}
.modal.dataModal .contentWrap .listingItem article:nth-child(1), .modal.dataModal .contentWrap .listingItem article:nth-child(3) {
  flex: 1;
}
.modal.dataModal .contentWrap .listingItem:hover {
  cursor: default;
  background: initial;
}

@media (min-width: 768px) {
  .modal.dataModal {
    justify-content: center;
  }
  .modal.dataModal .contentWrap {
    margin: 0;
  }
  .modal.dataModal .contentWrap .dataPointsForm {
    flex-direction: row;
  }
  .modal.dataModal .contentWrap .dataPointsForm .field {
    margin: 0 10px 0 0;
  }
  .modal.dataModal .contentWrap .dataPointsForm button {
    flex: 0;
    margin: 0;
    padding: 0 20px;
  }
}

