.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(127,127,127,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  .contentWrap {
    background: white;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgba(0,0,0,0.2);
    button {
      margin: 10px 0 0;
    }
  }
  h2 {
    margin: 0 0 10px;
  }
  .btnGroup {
    display: flex;
    gap: 20px;
    button {
      font-size: 1rem;
    }
    .cancelBtn {
      background: #f7f6fa;
      color: black;
      &:hover {
        background: #e8e7e7;
      }
    }
  }
  &.clientModal {
    .field {
      margin: 30px 0;
    }
  }
}

.titles {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid lightgrey;
  color: grey;
  font-size: 0.8rem;
  text-transform: capitalize;
  padding: 10px;
  box-sizing: border-box;
  article {
    flex: 1;
    &:nth-child(1),
    &:nth-child(3) {
      flex: 0.5;
    }
    &:last-child {
      flex: 0.1;
      width: 50px;
      display: block;
    }
  }
}

.listingItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
  padding: 10px;
  box-sizing: border-box;
  article {
    flex: 1;
    display: flex;
    align-items: center;
    &:nth-child(1),
    &:nth-child(3) {
      flex: 0.5;
    }
    &:last-child {
      flex: 0.1;
      text-align: right;
      width: 50px;
    }
    &.clientID {
      img {
        width: 10px;
        height: auto;
        margin: 0 5px 0 10px;
        opacity: 0.5;
        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
      span {
        font-size: 0.6rem;
      }
    }
    .trashBtn {
      width: 30px;
      height: 30px;
      border-radius: 9999px;
      background: none;
      padding: 0;
      margin: 0;
      position: relative;
      z-index: 1;
      opacity: 0.5;
      img {
        width: 50%;
        pointer-events: none;
      }
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
  &:hover {
    cursor: pointer;
    background: #f7f6fa;
  }
}

#Dashboard {
  header {
    background: white;
    .headerContentWrap {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
    }
    .clientLogo {
      height: auto;
      max-width: 200px;
    }
    .logoutBtn {
      font-size: 1rem;
      color: black;
      background: white;
      text-transform: capitalize;
      margin: 0;
      text-align: right;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 20px;
        width: auto;
        margin: 0 0 0 10px;
      }
    }
  }
  .contentWrap {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 0 20px;
    .clientTable {
      width: 100%;
      background: white;
      border-radius: 10px;
      padding: 20px;
      box-sizing: border-box;
      margin-right: 0;
    }
    .addBtn {
      margin-bottom: 20px;
    }
  }
}

@media (min-width: 768px) {
  .contentWrap {
    max-width: 800px;
    margin: 0 auto;
  }
  #Dashboard {
    header {
      .headerContentWrap {
        padding: 20px 0;
      }
    }
    .contentWrap {
      flex-direction: row;
      .clientTable {
        flex: 1;
        background: white;
        border-radius: 10px;
        padding: 20px;
        box-sizing: border-box;
        margin-right: 40px;
      }
      .addBtn {
        flex: 0 0 300px;
      }
    }
  }
}