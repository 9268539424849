.clientDeleteModal .contentWrap {
  max-width: 400px;
}
.clientDeleteModal .contentWrap .image {
  position: relative;
  margin: 20px 0 50px;
}
.clientDeleteModal .contentWrap .image img {
  width: 40px;
  display: block;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}
.clientDeleteModal .contentWrap .image .circle {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 999px;
}
.clientDeleteModal .contentWrap .image .circle.small {
  width: 60px;
  height: 60px;
  background: #f5d8da;
  z-index: 2;
}
.clientDeleteModal .contentWrap .image .circle.large {
  width: 80px;
  height: 80px;
  background: #faebeb;
  z-index: 1;
}
.clientDeleteModal .contentWrap h2 {
  text-align: center;
}
.clientDeleteModal .contentWrap p {
  font-size: 1rem;
}
.clientDeleteModal .contentWrap .btnGroup {
  display: flex;
  gap: 20px;
}
.clientDeleteModal .contentWrap .btnGroup button {
  font-size: 1rem;
}

