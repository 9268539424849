@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400&display=swap");
html,
body {
  font-family: sans-serif;
  background: #f6f7fa;
  margin: 0;
  padding: 0;
  color: #808285;
  font-family: "Poppins", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: black;
}

button {
  border: none;
  background: #57a7bb;
  color: white;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  font-weight: lighter;
  margin: 0;
  display: block;
  width: 100%;
  transition: all 0.2s ease-in-out;
  border-radius: 9999px;
}
button:hover {
  cursor: pointer;
  background: #408c9f;
  transition: all 0.2s ease-in-out;
}
button.cancelBtn {
  background: #f7f6fa;
  color: black;
}
button.cancelBtn:hover {
  background: #e8e7e7;
}
button.deleteBtn {
  background: #da3f41;
  color: white;
}
button.deleteBtn:hover {
  background: #c12527;
}

.field {
  position: relative;
}
.field label {
  display: block;
  text-transform: capitalize;
  font-size: 0.8rem;
  color: #808285;
  background: white;
  position: absolute;
  top: -50%;
  left: 10px;
  transform: translate(0, 100%);
  padding: 0 10px;
  letter-spacing: 1px;
}
.field input {
  width: 100%;
  font-size: 1.4rem;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  margin: 0;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-radius: 15px;
}

.capitalize {
  text-transform: capitalize;
}

.alert {
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  font-size: 0.8rem;
}
.alert.error {
  background: #da3f41;
  color: white;
}

